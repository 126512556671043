import moment from "moment-timezone"

// Function to get event date
export const getEventDate = (event) => {
  const currentDate = moment()
  const year = currentDate.year()

  // Calculate Labor Day (first Monday of September)
  let laborDay = moment(`${year}-09-01`).day(1) // Get the first Monday

  // If September 1st is not in the first week, adjust to the next Monday
  if (laborDay.date() > 7) {
    laborDay.add(1, "weeks")
  }

  // Adjust to the correct Friday and Saturday for the event
  const countryEdgeStartDate = laborDay
    .clone()
    .add(4, "days") // Friday at 5:30 PM (Labor Day Monday + 4 days)
    .set({ hour: 17, minute: 30 })
  const chilimaniaStartDate = laborDay
    .clone()
    .add(5, "days") // Saturday at 11:00 AM (Labor Day Monday + 5 days)
    .set({ hour: 11, minute: 0 })

  // Set the end of Chilimania to midnight
  const chilimaniaEndDate = chilimaniaStartDate.clone().endOf("day")

  // Determine if the current time is past the end of Chilimania
  const isAfterChilimania = currentDate.isAfter(chilimaniaEndDate)

  // If it's past the end of Chilimania, switch to next year's dates
  if (isAfterChilimania) {
    countryEdgeStartDate.add(1, "year")
    chilimaniaStartDate.add(1, "year")
  }

  // Return the appropriate event date
  if (event === "countryEdge") {
    return countryEdgeStartDate
      .tz("America/Chicago")
      .format("YYYY-MM-DD HH:mm:ss")
  } else if (event === "chilimania") {
    return chilimaniaStartDate
      .tz("America/Chicago")
      .format("YYYY-MM-DD HH:mm:ss")
  }
}
